<template>
    <v-form
        ref="form"
        v-model="isValid"
        @submit.prevent="save()">
        <v-card>
            <v-card-title>
                <span class="headline">{{ isEdit ? "Edit" : "Add" }} {{ entity.singleTitle }}</span>
            </v-card-title>

            <v-card-text>
                <component
                    :is="getComponent(field)"
                    v-for="(field, index) in filteredFields"
                    :key="field.value"
                    v-model="item[field.key]"
                    :field="field"
                    :autofocus="index == 0"
                    validate-on-blur
                    @save="save" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="pa-4">
                <app-button
                    v-if="canDelete"
                    color="error"
                    text
                    @click="deleteItem">
                    Delete
                </app-button>
                <v-spacer />
                <app-button
                    v-if="canCancel"
                    color="secondary"
                    text
                    @click="$emit('cancel')">
                    Cancel
                </app-button>
                <app-button
                    v-if="canSave"
                    type="submit"
                    text
                    :disabled="isSaving">
                    Save
                </app-button>
            </v-card-actions>

            <v-progress-linear v-if="isSaving" indeterminate color="blue" />
        </v-card>
    </v-form>
</template>

<script>
import formMixin from "@/features/schemas/mixins/formMixin";
import { mapState } from "vuex";

export default {
    mixins:[ formMixin ],
    types: [
        "default"
    ],
    computed: {
        ...mapState({
            user: s => s.authentication.user
        }),
        filteredFields() {
            let removedEditingFields = ["emailAddress", "name"]
            let schoolAdminFields = ["teacherRole"]
            let adminOnlyFields = ["schoolId"]

            return this.fields
                .filter(field =>
                    this.user.isAdmin ||
                    (this.user.isSchoolAdmin && schoolAdminFields.includes(field.key)) ||
                    (!adminOnlyFields.includes(field.key) && !schoolAdminFields.includes(field.key))
                )
                .filter(field => !this.isEdit || !removedEditingFields.includes(field.key))
        }
    }
}
</script>
